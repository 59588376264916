import { GlobalStateProvider } from "app/apps/main/GlobalStateProvider"
import Layout from "app/apps/main/Layout"
import React from "react"
import { BrowserRouter as Router } from "react-router-dom"

const App: React.FC<Obj> = () => {
  return (
    <React.StrictMode>
      <GlobalStateProvider>
        <Router>
          <Layout />
        </Router>
      </GlobalStateProvider>
    </React.StrictMode>
  )
}

export default App
