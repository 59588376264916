/* eslint-disable camelcase */
/* eslint-disable react/self-closing-comp */
import React from "react"

const icons = {
  arrow_right: props => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="#000000"
      strokeWidth="0.00024"
      transform="rotate(0)"
      {...props}
    >
      <path
        d="M15.1997 10.4919L13.2297 8.52188L10.0197 5.31188C9.33969 4.64188 8.17969 5.12188 8.17969 6.08188V12.3119V17.9219C8.17969 18.8819 9.33969 19.3619 10.0197 18.6819L15.1997 13.5019C16.0297 12.6819 16.0297 11.3219 15.1997 10.4919Z"
        fill="#7c7c7c"
      />
    </svg>
  ),

  // https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/emergency_home/wght300/24px.svg
  emergency_home: props => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      fill="currentColor"
      {...props}
    >
      <path d="M480-104.002q-14.077 0-27.231-5.423-13.153-5.423-23.999-15.654L125.079-428.77q-10.231-10.846-15.654-23.999-5.423-13.154-5.423-27.231 0-14.077 5.423-27.538 5.423-13.462 15.654-23.692L428.77-834.921q10.846-10.846 23.999-15.962 13.154-5.115 27.231-5.115 14.077 0 27.538 5.115 13.462 5.116 23.692 15.962L834.921-531.23q10.846 10.23 15.962 23.692 5.115 13.461 5.115 27.538t-5.115 27.231q-5.116 13.153-15.962 23.999L531.23-125.079q-10.23 10.231-23.692 15.654-13.461 5.423-27.538 5.423Zm8.847-63.845 303.306-303.306q3.077-3.077 3.077-8.847t-3.077-8.847L488.847-792.153q-3.077-3.077-8.847-3.077t-8.847 3.077L167.847-488.847q-3.077 3.077-3.077 8.847t3.077 8.847l303.306 303.306q3.077 3.077 8.847 3.077t8.847-3.077Zm-38.846-268.691h59.998v-224.614h-59.998v224.614ZM480-331.924q13.538 0 22.923-9.384 9.384-9.385 9.384-22.923 0-13.538-9.384-22.923-9.385-9.384-22.923-9.384-13.538 0-22.923 9.384-9.384 9.385-9.384 22.923 0 13.538 9.384 22.923 9.385 9.384 22.923 9.384ZM480-480Z"></path>
    </svg>
  ),

  // https://phosphoricons.com/?q=%22envelope%22
  envelope: props => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 256 256"
      fill="currentColor"
      {...props}
    >
      <path d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48ZM203.43,64,128,133.15,52.57,64ZM216,192H40V74.19l82.59,75.71a8,8,0,0,0,10.82,0L216,74.19V192Z" />
    </svg>
  ),

  // https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/error/default/24px.svg
  error: props => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      fill="currentColor"
      {...props}
    >
      <path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path>
    </svg>
  ),

  folder_beige: props => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800px"
      height="800px"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path
        d="M390.4 200.8l-8-8c-10.4-10.4-24-16-38.4-16H180.8c-29.6 0-54.4 24-54.4 54.4v562.4c0 29.6 24 54.4 54.4 54.4h757.6c29.6 0 54.4-24 54.4-54.4V270.4c0-29.6-24-54.4-54.4-54.4H428.8c-14.4 0.8-28-4.8-38.4-15.2z"
        fill="#F1ED7B"
      />
      <path
        d="M390.4 200.8l-8-8c-10.4-10.4-24-16-38.4-16H180.8c-29.6 0-54.4 24-54.4 54.4v562.4c0 29.6 24 54.4 54.4 54.4h757.6c29.6 0 54.4-24 54.4-54.4V270.4c0-29.6-24-54.4-54.4-54.4H428.8c-14.4 0.8-28-4.8-38.4-15.2z"
        fill="#0E1115"
      />
      <path
        d="M937.6 855.2H180.8c-34.4 0-62.4-28-62.4-62.4V230.4c0-34.4 28-62.4 62.4-62.4H344c16.8 0 32 6.4 44 18.4l8 8c8.8 8.8 20 13.6 32.8 13.6h509.6c34.4 0 62.4 28 62.4 62.4v522.4c-0.8 34.4-28.8 62.4-63.2 62.4zM180.8 184.8c-25.6 0-46.4 20.8-46.4 46.4v562.4c0 25.6 20.8 46.4 46.4 46.4h757.6c25.6 0 46.4-20.8 46.4-46.4V270.4c0-25.6-20.8-46.4-46.4-46.4H428.8c-16.8 0-32-6.4-44-18.4l-8-8C368 188.8 356.8 184 344 184H180.8z"
        fill="#6A576D"
      />
      <path
        d="M208 256.8h703.2c29.6 0 54.4 24 54.4 54.4v449.6c0 29.6-24 54.4-54.4 54.4H208c-29.6 0-54.4-24-54.4-54.4V311.2c0-29.6 24-54.4 54.4-54.4z"
        fill="#FFFFFF"
      />
      <path
        d="M911.2 823.2H208c-34.4 0-62.4-28-62.4-62.4V311.2c0-34.4 28-62.4 62.4-62.4h703.2c34.4 0 62.4 28 62.4 62.4v449.6c-0.8 34.4-28.8 62.4-62.4 62.4zM208 264.8c-25.6 0-46.4 20.8-46.4 46.4v449.6c0 25.6 20.8 46.4 46.4 46.4h703.2c25.6 0 46.4-20.8 46.4-46.4V311.2c0-25.6-20.8-46.4-46.4-46.4H208z"
        fill="#6A576D"
      />
      <path
        d="M153.6 836.8l-38.4-477.6c-2.4-31.2 22.4-58.4 53.6-58.4H872c28 0 52 21.6 53.6 49.6l39.2 486.4"
        fill="#FFFFFF"
      />
      <path
        d="M964.8 844.8c-4 0-8-3.2-8-7.2l-39.2-486.4c-1.6-24-22.4-42.4-45.6-42.4H168.8c-12.8 0-24.8 5.6-33.6 15.2s-12.8 21.6-12 35.2l38.4 477.6c0 4-3.2 8-7.2 8.8-4 0-8-3.2-8.8-7.2l-38.4-477.6c-1.6-17.6 4.8-34.4 16-47.2 12-12.8 28-20 45.6-20H872c32 0 59.2 24.8 61.6 56.8l39.2 486.4c0.8 3.2-3.2 7.2-8 8 0.8 0 0 0 0 0z"
        fill="#6A576D"
      />
      <path d="M153.6 847.2l-64.8-524.8" fill="#0E1115" />
      <path
        d="M153.6 836.8L68.8 408c-6.4-33.6 19.2-64 53.6-64h700.8c25.6 0 48 18.4 52.8 44l88.8 449.6"
        fill="#FFFFFF"
      />
      <path
        d="M964.8 844.8c-4 0-7.2-2.4-8-6.4l-88.8-449.6c-4-21.6-23.2-36.8-45.6-36.8H122.4c-13.6 0-27.2 6.4-36 16.8s-12 24.8-9.6 38.4l84.8 428c0.8 4-1.6 8.8-6.4 9.6-4 0.8-8.8-1.6-9.6-6.4L60.8 409.6c-4-18.4 0.8-36.8 12.8-51.2 12-14.4 29.6-22.4 48-22.4h700.8c29.6 0 55.2 20.8 60.8 50.4l88.8 449.6c0.8 4-1.6 8.8-6.4 9.6 0-0.8 0-0.8-0.8-0.8z"
        fill="#6A576D"
      />
      <path
        d="M86.4 371.2h768c25.6 0 47.2 17.6 52.8 42.4l83.2 368c8 33.6-18.4 66.4-52.8 66.4h-768c-25.6 0-47.2-17.6-52.8-42.4l-83.2-368c-8-34.4 17.6-66.4 52.8-66.4z"
        fill="#F1ED7B"
      />
      <path
        d="M937.6 855.2h-768c-28.8 0-54.4-20-60.8-48l-83.2-368c-4-18.4 0-37.6 12-52.8 12-15.2 29.6-23.2 48.8-23.2h768c28.8 0 54.4 20 60.8 48l83.2 368c4 18.4 0 37.6-12 52.8-12 15.2-29.6 23.2-48.8 23.2zM86.4 379.2c-14.4 0-27.2 6.4-36 17.6-8.8 11.2-12 25.6-8.8 39.2l83.2 368c4.8 20.8 23.2 36 44.8 36h768c14.4 0 27.2-6.4 36-17.6 8.8-11.2 12-25.6 8.8-39.2l-83.2-368c-4.8-20.8-23.2-36-44.8-36h-768z"
        fill="#6A576D"
      />
    </svg>
  ),

  folder_blue: props => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800px"
      height="800px"
      viewBox="0 0 512 512"
      aria-hidden="true"
      role="img"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        fill="#0074A8"
        d="M491 326V92.143C491 79.021 480.259 68 467.137 68H341.13c-9.287 0-17.723 5.603-21.596 14.044l-12.709 27.903C302.952 118.388 294.516 124 285.229 124H67.538C54.416 124 44 134.426 44 147.549v216.363C44 377.034 54.416 388 67.538 388h399.599c.628 0 1.248-.36 1.863-.408V433h.342c0 6 4.877 10.636 10.829 10.636c5.952 0 10.829-4.967 10.829-10.919V326z"
      ></path>
      <path
        fill="#59CAFC"
        d="M480.171 443.636c-5.952 0-10.829-4.636-10.829-10.636H469V210.181C469 197.058 458.661 186 445.539 186H45.94C32.818 186 22 197.058 22 210.181V449.37C22 462.492 32.818 473 45.94 473h399.599c1.385 0 2.741-.06 4.061-.288c1.639.227 3.31.385 5.012.385c20.04 0 36.136-16.229 36.136-36.269c0-.534-.036-1.058-.058-1.586c-1.147 4.766-5.435 8.394-10.519 8.394z"
      ></path>
    </svg>
  ),

  // https://phosphoricons.com/?q=%22github%22
  github_logo: props => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="currentColor"
      viewBox="0 0 256 256"
      {...props}
    >
      <path d="M208.31,75.68A59.78,59.78,0,0,0,202.93,28,8,8,0,0,0,196,24a59.75,59.75,0,0,0-48,24H124A59.75,59.75,0,0,0,76,24a8,8,0,0,0-6.93,4,59.78,59.78,0,0,0-5.38,47.68A58.14,58.14,0,0,0,56,104v8a56.06,56.06,0,0,0,48.44,55.47A39.8,39.8,0,0,0,96,192v8H72a24,24,0,0,1-24-24A40,40,0,0,0,8,136a8,8,0,0,0,0,16,24,24,0,0,1,24,24,40,40,0,0,0,40,40H96v16a8,8,0,0,0,16,0V192a24,24,0,0,1,48,0v40a8,8,0,0,0,16,0V192a39.8,39.8,0,0,0-8.44-24.53A56.06,56.06,0,0,0,216,112v-8A58.14,58.14,0,0,0,208.31,75.68ZM200,112a40,40,0,0,1-40,40H112a40,40,0,0,1-40-40v-8a41.74,41.74,0,0,1,6.9-22.48A8,8,0,0,0,80,73.83a43.81,43.81,0,0,1,.79-33.58,43.88,43.88,0,0,1,32.32,20.06A8,8,0,0,0,119.82,64h32.35a8,8,0,0,0,6.74-3.69,43.87,43.87,0,0,1,32.32-20.06A43.81,43.81,0,0,1,192,73.83a8.09,8.09,0,0,0,1,7.65A41.72,41.72,0,0,1,200,104Z"></path>
    </svg>
  ),

  // https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/info/default/24px.svg
  info: props => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      fill="currentColor"
      {...props}
    >
      <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path>
    </svg>
  ),

  lock_private_black: props => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="currentColor"
      {...props}
    >
      <path d="M24,14H22V8A6,6,0,0,0,10,8v6H8a2,2,0,0,0-2,2V28a2,2,0,0,0,2,2H24a2,2,0,0,0,2-2V16A2,2,0,0,0,24,14ZM12,8a4,4,0,0,1,8,0v6H12ZM24,28H8V16H24Z" />
    </svg>
  ),
} satisfies Record<string, React.FC<React.SVGProps<SVGSVGElement>>>

export default icons
