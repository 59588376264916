import { Contact } from "app/components/Contact"
import distn from "app/images/distn.png"
import logo from "app/images/favicon.png"
import cx from "classnames"
import React, { useState } from "react"
import { Link } from "react-router-dom"

const Header: React.FC = () => {
  // const toggleEnvColoring = useToggleAppearance(setEnv)
  const [show, _setShow] = useState(true)
  const headerText = "jonah.golden"

  return (
    <nav
      id="header"
      className={cx("relative drop-in border-gray-500 border-b", { show })}
      // onDoubleClick={toggleEnvColoring}
    >
      <div className="flex items-center">
        <div className="flex items-center p-2 flex-shrink-0">
          <Link to="/" className="mr-4">
            <img src={logo} alt="Jonah Lab" width={64} className="min-w-8" />
          </Link>
          <div className="text-4xl">{headerText}</div>
        </div>

        <div className="pb-[1px] flex items-center flex-shrink-0">
          {Array.from(Array(20).keys()).map((_, i) => (
            <img key={`distn-${i}`} src={distn} width={101} height={94} />
          ))}
        </div>
      </div>
      <div className="hidden sm:block absolute top-0 bottom-0 right-0 my-auto h-fit p-2">
        <Contact />
      </div>
    </nav>
  )
}

export default Header
