import { useGlobalState } from "app/apps/main/GlobalStateProvider"
import { NavItemProps } from "app/apps/main/nav/NavBar"
import Icon from "app/components/icons/Icon"
import Folder from "app/images/folder.png"
import cx from "classnames"
import React from "react"
import { Link } from "react-router-dom"

interface Props {
  navItem: NavItemProps
  selected: boolean
}

const NavItem: React.FC<Props> = ({ navItem, selected }) => {
  const { navLayout } = useGlobalState()
  const iconSrc = navItem.icon || Folder

  return (
    <div
      id="nav-item"
      className={cx("active:bg-blue-600 active:text-white", {
        "bg-gray-200": selected,
      })}
    >
      <Link to={navItem.path}>
        <div className="flex flex-nowrap text-nowrap py-1 px-[6px] items-center">
          <img src={iconSrc} width={24} height={24} />
          <span className="mr-auto ml-1">{navItem.title}</span>

          <Icon
            name="arrow_right"
            size={24}
            className={cx("flex-shrink-0", {
              "rotate-90": navLayout === "top",
            })}
          />
        </div>
      </Link>
    </div>
  )
}

export default NavItem
