import React from "react"

const Feed: React.FC = () => {
  return (
    <div>
      <article>
        <main />
      </article>
    </div>
  )
}

export default Feed
