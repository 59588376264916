import { useGlobalState } from "app/apps/main/GlobalStateProvider"
import NavItem from "app/apps/main/nav/NavItem"
import cx from "classnames"
import React, { useEffect } from "react"
import { matchPath, useLocation } from "react-router-dom"

export type NavItemProps = {
  title: string
  path: string
  icon?: string
  children?: NavItemProps[]
}

export const NAV_ITEMS: NavItemProps[] = [
  {
    title: "welcome",
    path: "/welcome",
  },
  {
    title: "feed",
    path: "/feed",
  },
  {
    title: "stuff i like",
    path: "/stuff-i-like",
  },
  {
    title: "lab",
    path: "/lab",
  },
  {
    title: "contact",
    path: "/contact",
  },
]

const NavBar: React.FC = () => {
  const { navLayout, setGlobalState } = useGlobalState()

  const { pathname } = useLocation()

  const selectedNavItem = NAV_ITEMS.find(navItem => {
    let path = [navItem.path]
    if (navItem.children) {
      path = path.concat(navItem.children.map(child => child.path))
    }
    return !!matchPath(pathname, { path })
  })

  useEffect(() => {
    setGlobalState({ selectedNavItem })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNavItem])

  return (
    <div id="nav-wrapper">
      <nav
        id={`${navLayout}nav`}
        className={cx("flex", {
          "flex-col": navLayout === "side",
          "flex-row flex-wrap": navLayout === "top",
        })}
      >
        {NAV_ITEMS.map(navItem => (
          <NavItem
            key={navItem.title}
            selected={selectedNavItem?.path === navItem.path}
            navItem={navItem}
          />
        ))}
      </nav>
    </div>
  )
}

// interface SideNav2Props {
//   navItem: NavItemProps
// }
// const SideNav2: React.FC<SideNav2Props> = ({ navItem }) => {
//   const { pathname } = useLocation()

//   if (!navItem.children) {
//     return null
//   }

//   const selectedChild = navItem.children.find(item => {
//     return pathname === item.path
//   })

//   return (
//     <nav id="sidenav" className="border-gray-500 border-r">
//       {navItem.children.map(item => (
//         <NavItem
//           key={item.title}
//           selected={selectedChild === item}
//           navItem={item}
//         />
//       ))}
//     </nav>
//   )
// }

export default NavBar
