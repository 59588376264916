import React from "react"

export const WelcomePage: React.FC = () => {
  return (
    <div className="text-left flex flex-col gap-2">
      <p>Hi. I'm Jonah Golden.</p>
      <p>documenting insomnia-driven side projects</p>
      <p>using data to give partial answers to random questions</p>
      <p>experimenting</p>
      <p>and sharing some stuff i like</p>
    </div>
  )
}
