import { NavItemProps } from "app/apps/main/nav/NavBar"
import React, { createContext, useContext, useEffect, useState } from "react"

export type Breadcrumb = {
  title: string
  path?: string
  inTitle?: boolean
}

type GlobalState = {
  breadcrumbs: Breadcrumb[]
  isEditing: boolean
  selectedNavItem?: NavItemProps
  modalProps: { show: boolean }
  navLayout: "side" | "top"
}

const GlobalStateContext = createContext<
  GlobalState & {
    setGlobalState: (state: Partial<GlobalState>) => void
  }
>(undefined as never)

interface Props {
  children: React.ReactNode
}

export const GlobalStateProvider: React.FC<Props> = ({ children }) => {
  const [state, setState] = useState<GlobalState>({
    breadcrumbs: [],
    isEditing: false,
    selectedNavItem: undefined,
    modalProps: { show: false },
    navLayout: window.innerWidth > 1159 ? "side" : "top",
  })

  const setGlobalState = (newState: Partial<GlobalState>) => {
    setState({ ...state, ...newState })
  }

  // Set navLayout based on window width
  useEffect(() => {
    const setNavLayout = () =>
      setGlobalState({
        navLayout: window.innerWidth > 1159 ? "side" : "top",
      })

    window.addEventListener("resize", setNavLayout)
    return () => {
      window.removeEventListener("resize", setNavLayout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <GlobalStateContext.Provider value={{ ...state, setGlobalState }}>
      {children}
    </GlobalStateContext.Provider>
  )
}

export const useGlobalState = () => useContext(GlobalStateContext)
