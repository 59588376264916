import { useGlobalState } from "app/apps/main/GlobalStateProvider"
import Routes from "app/apps/main/Routes"
import Header from "app/apps/main/nav/Header"
import NavBar from "app/apps/main/nav/NavBar"
import Spinner from "app/components/Spinner"
import * as React from "react"

const Layout: React.FC = () => {
  const { navLayout } = useGlobalState()

  return (
    <div className={`nav-layout-${navLayout}`}>
      <header className="border-b-1 overflow-hidden fixed top-0">
        <Header />
      </header>
      <div id="wrapper">
        <div
          id="navbar-wrapper"
          className="font-medium border-gray-500 border-r"
        >
          <NavBar />
        </div>
        <main id="main">
          <div className="container-fluid p-2">
            <React.Suspense fallback={<Spinner size="md" />}>
              <Routes />
            </React.Suspense>
          </div>
        </main>
      </div>
    </div>
  )
}

export default Layout
