import icons from "app/components/icons/icons"
import React from "react"

export type IconName = keyof typeof icons
export const iconNames = Object.keys(icons) as IconName[]
interface Props {
  name: IconName
  size?: string | number
  viewBox?: string
  fill?: string
  className?: string
  style?: React.CSSProperties
}

const Icon = React.memo(({ name, size, className, ...props }: Props) => {
  const I = icons[name]
  if (size) {
    return <I width={size} height={size} {...props} className={className} />
  } else {
    // don't override default w/h if size not passed
    return <I {...props} className={className} />
  }
})

Icon.displayName = "Icon"

export default Icon
