import Icon from "app/components/icons/Icon"
import React from "react"
import { Link } from "react-router-dom"

export const Contact: React.FC = () => {
  return (
    <div className="flex items-center gap-2">
      <Link
        className="group cursor-pointer"
        to="#"
        onClick={e => {
          window.location.href = "mailto:jonahwgolden@gmail.com"
          e.preventDefault()
        }}
      >
        <Icon name="envelope" className="group-hover:fill-salmon" />
      </Link>
      <Link
        className="group cursor-pointer"
        to={{ pathname: "https://github.com/jonahgolden" }}
        target="_blank"
        rel="noreferrer"
      >
        <Icon name="github_logo" className="group-hover:fill-salmon" />
      </Link>
    </div>
  )
}
