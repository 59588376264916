import Feed from "app/apps/pages/Feed"
import { WelcomePage } from "app/apps/pages/WelcomePage"
import { Contact } from "app/components/Contact"
import ERoute from "app/components/wrappers/ERoute"
import React from "react"
import { Redirect, Switch } from "react-router-dom"

const Routes: React.FC = () => {
  return (
    <Switch>
      <Redirect from="/" to="/feed" exact />
      <ERoute path="/welcome" exact>
        <WelcomePage />
      </ERoute>
      <ERoute path="/feed" exact>
        <Feed />
      </ERoute>
      <ERoute path="/stuff-i-like/products" exact>
        <div>a few of my favorite things</div>
      </ERoute>
      <ERoute path="/stuff-i-like/resources" exact>
        <div>table</div>
      </ERoute>
      <ERoute path="/lab" exact>
        <div>login</div>
      </ERoute>
      <ERoute path="/contact" exact>
        <div className="flex justify-center">
          <Contact />
        </div>
      </ERoute>
    </Switch>
  )
}

export default Routes
